
import debounce from './global/debounce';


function resizeVideo(el, videoRatioContainer) {
	videoRatioContainer.style.transform = '';
	const viewportHeight = el.clientHeight
	const videoHeight = videoRatioContainer.clientHeight
	const scale = (viewportHeight + 1) / videoHeight
	const style = `scale(${scale}) translate(-50%, -50%)`
	if (videoHeight <= viewportHeight) {
		window.requestAnimationFrame && requestAnimationFrame(() => videoRatioContainer.style.transform = videoRatioContainer.style.webkitTransform = style)
		!window.requestAnimationFrame && (videoRatioContainer.style.transform = videoRatioContainer.style.webkitTransform = style)
	}
}

export const initInstance = function(el) {
	const videoRatioContainer = el.querySelector('.m-video-fit__ratio')
	const resize = () => resizeVideo(el, videoRatioContainer)
	const resizeDebounced = debounce(resize, 100)
	resize();
	window.addEventListener('resize', resizeDebounced)
	window.addEventListener('load', resizeDebounced)
	window.addEventListener('videoRatio:resize', resize)
}
